import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  SectionTitle,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import styled from "styled-components"

import { BreadCrumb } from "../components/Section"
import ProductImageCarousel from "../components/ProductImageCarousel"
import ProductCardList from "../components/ProductCardList"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import generateHTML from "../utils/generateHTML"

const Wrapper = styled.div`
  padding-top: 207px;
  padding-bottom: 44px;
  & h1 {
    margin-top: 50px;
  }
  @media (max-width: 768px) {
    padding-top: 150px;
  }
`
const HeroContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-column-gap: 30px;
  grid-template-row: auto;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 50px;
    margin: auto;
  }
`

class ProductOtherBarnsTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulProductCategoryLanding
    const currentData = this.props.data.allContentfulProduct.edges.filter(
      edge =>
        edge.node.productCategory &&
        edge.node.productCategory.name &&
        edge.node.productRoofType &&
        edge.node.productRoofType.name &&
        edge.node.productCategory.name === pageData.category.name
    )

    return (
      <Layout location={this.props.location}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
        />
        <Wrapper>
          <div className="container">
            <BreadCrumb top="0px">
              <Link to="/">Home </Link> / <Link to="/barns">Barns </Link> /{" "}
              <span>{pageData.title}</span>
            </BreadCrumb>
            <HeroContent>
              <div>
                <h1>{pageData.heroTitle}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </div>
              <div>
                <ProductImageCarousel
                  product={currentData}
                  location={this.props.location}
                />
              </div>
            </HeroContent>
          </div>
        </Wrapper>
        <Section pt="95px" pb="90px" bg="#F4FBFF">
          <SectionTitle>{pageData.productListTitle}</SectionTitle>
          <div className="container">
            <ProductCardList
              data={currentData}
              location={this.props.location}
            />
          </div>
        </Section>
        <CallToAction />
        <Section pt="90px" pb="90px">
          <div className="container">
            <LeftPannel>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: generateHTML(pageData.content.content),
                }}
              />
            </LeftPannel>
            <RightPannel>
              <DesignPlan />
              <AccreditedBusiness />
            </RightPannel>
          </div>
        </Section>
      </Layout>
    )
  }
}

export default ProductOtherBarnsTemplate

export const pageQuery = graphql`
  query ProductOtherBarnsTemplateQuery($id: String!) {
    contentfulProductCategoryLanding(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      category {
        name
      }
      productListTitle
      content {
        content
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          price
          priceDescription
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
        }
      }
    }
  }
`
